import * as React from 'react'
import Layout from '../components/layout/page-layout'
import FadeInWrapper from '../components/animated/fade-wrapper'

// markup
const NotFoundPage = () => {
  return (
    <Layout title='Not Found'>
      <div className='row'>
        <div className='col'>
          <FadeInWrapper>
            <h1>Not Found</h1>
            <p>The page you are looking for does not exist... yet. It also might never exist (sorry).</p>
          </FadeInWrapper>
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
